.table {
    width: 100%;
    border-collapse: collapse;
}

.td,
.th {
    padding: 8px;
    text-align: left;
    border: 1px solid #dddddd;
}

.th {
    font-weight: bold;
}