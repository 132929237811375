.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 40%;
    -moz-column-width: 40%;
    column-width: 40%;
}

.gallery .image {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 0.3rem;
}

.gallery .image:hover {
    filter: opacity(0.8);
}

@media (max-width:991px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (max-width:480px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
    }
}